import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frequently-asked-questions-faq",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#frequently-asked-questions-faq",
        "aria-label": "frequently asked questions faq permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Frequently Asked Questions (FAQ)`}</h1>
    <p>{`Don't see your question here? Feel free to ask on
`}<a parentName="p" {...{
        "href": "https://spectrum.chat/popper-js"
      }}>{`Spectrum`}</a>{`! We'll try to answer you within a
day or two.`}</p>
    <h2 {...{
      "id": "why-is-my-popper-in-the-wrong-location-or-not-visible-at-all",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-is-my-popper-in-the-wrong-location-or-not-visible-at-all",
        "aria-label": "why is my popper in the wrong location or not visible at all permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why is my popper in the wrong location (or not visible at all)?`}</h2>
    <p>{`For the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`left`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top`}</code>{` placements, Popper relies on HTML Standards Mode for
the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`computeStyles`}</code>{` modifier's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`adaptive`}</code>{` option. A problem will occur in Quirks
Mode when the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<body>`}</code>{` is the popper element's offsetParent, and it's taller
than the viewport.`}</p>
    <p>{`To fix it, use the Standards Mode doctype:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token doctype"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<!`}</span><span parentName="span" {...{
              "className": "token doctype-tag"
            }}>{`DOCTYPE`}</span>{` `}<span parentName="span" {...{
              "className": "token name"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- ... -->`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Additionally, make sure your popper element (tooltip, popover, etc...) CSS
doesn't include some positioning styles (such as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`top`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`left`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`right`}</code>{`,
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bottom`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform`}</code>{`) because they could interfere with the Popper
positioning logic.`}</p>
    <h2 {...{
      "id": "why-is-my-popper-jittering-while-scrolling",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-is-my-popper-jittering-while-scrolling",
        "aria-label": "why is my popper jittering while scrolling permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why is my popper jittering while scrolling?`}</h2>
    <p>{`If your reference element is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position: fixed`}</code>{`, use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"fixed"`}</code>{` strategy:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  strategy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fixed'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Due to the way browsers handle element updates, there will likely be some
inevitable stutters if the strategy does not match the reference element's
position.`}</p>
    <h2 {...{
      "id": "how-do-i-add-css-transitions-without-disabling-adaptive",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-do-i-add-css-transitions-without-disabling-adaptive",
        "aria-label": "how do i add css transitions without disabling adaptive permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I add CSS transitions without disabling `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`adaptive`}</code>{`?`}</h2>
    <p>{`We recommend making the popper element a wrapper around an inner element that
can have any CSS property transitioned:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`popper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`box`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    Content
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`In the above case, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.popper`}</code>{` element is the one that's positioned but has no
styles applied. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.box`}</code>{` element has all of the CSS styling and animations.`}</p>
    <h2 {...{
      "id": "how-do-i-set-modifier-defaults-and-allow-them-to-be-overridden",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-do-i-set-modifier-defaults-and-allow-them-to-be-overridden",
        "aria-label": "how do i set modifier defaults and allow them to be overridden permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I set modifier defaults and allow them to be overridden?`}</h2>
    <p>{`Modifiers are merged by name, where later items in the array override earlier
ones. This provides a way to configure some defaults for modifiers, but allow
them to be overridden easily:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// A user passes this object in:`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` popperOptions `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  strategy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fixed'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'preventOverflow'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        padding`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Your library sets its own defaults:`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`popperOptions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'preventOverflow'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        rootBoundary`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'document'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        padding`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`popperOptions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`modifiers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "how-do-i-change-offset-based-on-the-browser-width-media-queries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-do-i-change-offset-based-on-the-browser-width-media-queries",
        "aria-label": "how do i change offset based on the browser width media queries permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I change `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`offset`}</code>{` based on the browser width (media queries)?`}</h2>
    <p>{`Popper doesn't accept margins, but you can still make offset dynamic based on
media queries.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`window.matchMedia()`}</code>{` is a useful API for this — since `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offset`}</code>{` can take a
function, it allows you to dynamically change the offset based on a condition:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` mediaQuery `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`matchMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'(max-width: 500px)'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'offset'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 0px distance at <= 500px width, otherwise 10px distance`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`offset`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mediaQuery`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`matches `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "my-popper-is-bigger-than-the-viewport-what-do-i-do",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#my-popper-is-bigger-than-the-viewport-what-do-i-do",
        "aria-label": "my popper is bigger than the viewport what do i do permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`My popper is bigger than the viewport, what do I do?`}</h2>
    <p>{`Here are some options:`}</p>
    <ul>
      <li parentName="ul">{`Prevent the popper's width from ever exceeding the browser's width with
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`max-width: 100vw;`}</code>{` CSS (along with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`box-sizing: border-box`}</code>{`).`}</li>
      <li parentName="ul">{`Set `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`preventOverflow`}</code>{`'s `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rootBoundary`}</code>{` option to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`document`}</code>{` if it must be
larger than the viewport.`}</li>
      <li parentName="ul">{`Create a custom `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`size`}</code>{` modifier that changes the size of the popper based on
the available space remaining, and allow the popper box to be scrolled. The
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`detectOverflow`}</code>{` utility enables this.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      